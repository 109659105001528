<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Detalhes do Cliente`">
      <template v-slot:breadcrumbs>
        <li>Clientes</li>
        <li>Detalhes</li>
      </template>
    </page-header>

    <div class="row mt-5">
      <div class="col-3">
        <div class="card card-raised border-start border-secondary border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ client.condos_count }}</div>
                <div class="card-text">Condomínios</div>
              </div>
              <div class="icon-circle bg-secondary text-white">
                <i class="la la-city"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-primary border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ client.properties_count }}</div>
                <div class="card-text">Imóveis</div>
              </div>
              <div class="icon-circle bg-primary text-white">
                <i class="la la-home"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-success border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ client.portals.length }}</div>
                <div class="card-text">Portais</div>
              </div>
              <div class="icon-circle bg-success text-white">
                <i class="la la-project-diagram"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="card card-raised border-start border-warning border-2 widget">
          <div class="card-body px-4">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="me-2">
                <div class="number">{{ client.users_count }}</div>
                <div class="card-text">Usuários</div>
              </div>
              <div class="icon-circle bg-warning text-white">
                <i class="la la-user" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <div class="card bg-white">
          <div class="card-body p-4 position-relative">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title text-primary">Dados do Cliente</h6>
              <span class="badge bg-success" v-if="client.status === 1">Ativo</span>
              <span class="badge bg-danger" v-if="client.status === 2">Inativo</span>
              <span class="badge bg-info" v-if="client.status === 4">Trial</span>
              <span class="badge bg-warning" v-if="client.status === 3">Bloqueado</span>
              <span class="badge bg-danger" v-if="client.status === 5">Trial Expirado</span>
            </div>

            <div class="row mt-4">
              <div class="col-4">
                <img class="img-fluid rounded rounded-3" :src="client.logo_url ? client.logo_url : 'https://via.placeholder.com/200?text=Logotipo'" width="200px"/>
              </div>

              <div class="col-8">
                <h6 class="text-primary">{{ client.name }}</h6>
                <div class="d-flex mt-3">
                  <i class="la la-map-marker la-2x text-primary" />

                  <div>
                    <h5 class="section-title">Endereço</h5>
                    <span class="address">{{ `${client.address}, ${client.number}`}}</span>
                    <span class="address">{{ `${client.neighborhood} - ${client.city}, ${client.state?.uf}` }}</span>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-5">
                <button class="btn btn-primary me-2" @click.prevent="$router.push(`/clientes/edit/${client.id}`)"><i class="la la-pencil"/> Editar</button>
                <button class="btn btn-success me-2" :class="{disabled: !client.whatsapp}" @click.prevent="openWhatsapp"><i class="la la-whatsapp"/> Whatsapp</button>
                <button class="btn btn-secondary me-2" @click.prevent="login"><i class="la la-key"/> Acessar </button>
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="openStatusModal"
                >
                    <i class="la la-exchange"/> Situação
                </button>

                <button
                    class="btn btn-danger"
                    @click.prevent="openResetModal"
                >
                  <i class="la la-trash"/> Resetar
                </button>
              </div>
            </div>

          </div>
        </div>

        <modal id="status-modal" :custom-classes="['modal-dialog-centered', 'modal-md']" title="Mudança de status">
          <template #body>
            <div class="row g-2">
              <div class="col-12">
                <label class="form-label">Status</label>
                <select class="form-select form-select-sm" v-model="statusPayload.status">
                  <option
                      v-for="status in availableStatus"
                      :key="`status-${status.id}`"
                      :value="status.id"
                  >
                    {{ status.label }}
                  </option>
                </select>
              </div>

              <div class="col-12">
                <label class="form-label">Motivo</label>
                <textarea class="form-control" v-model="statusPayload.reason"></textarea>
              </div>


            </div>
          </template>

          <template #footer>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" ref="closeModal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click.prevent="toggleStatus">Salvar</button>
          </template>
        </modal>

        <modal id="reset-modal" :custom-classes="['modal-dialog-centered', 'modal-md']" title="Resetar dados">
          <template #body>
            <div class="row g-2">

              <div class="alert alert-danger">
                <strong>Atenção!</strong>
                <p>Todos os Imóveis e Condomínios serão excluídos! <br/> Essa ação não poderá ser desfeita.</p>
                <p>Para prosseguir digite <strong>excluir permanentemente</strong></p>
              </div>

              <div class="col-12">
                <label class="form-label">Sua resposta</label>
                <input
                    type="text"
                    class="form-control"
                    :class="{'is-invalid': resetPayload.validation && resetPayload.validation !== 'excluir permanentemente'}"
                    v-model="resetPayload.validation"
                />
                <span class="invalid-feedback d-block" v-if="resetPayload.validation && resetPayload.validation !== 'excluir permanentemente'">Resposta inválida</span>
              </div>


            </div>
          </template>

          <template #footer>
            <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                ref="closeModal"
            >
              Cancelar
            </button>
            <button
                type="button"
                class="btn btn-primary"
                :class="{'disabled': resetPayload.validation !== 'excluir permanentemente'}"
                @click.prevent="resetCompany"
            >
              Excluir dados
            </button>
          </template>
        </modal>

        <div class="card bg-white mt-4">
          <div class="card-body p-4">
            <div class="d-flex justify-content-between">
              <h6 class="card-title text-primary">Importação de dados</h6>
              <div>
                <button
                    class="btn btn-sm btn-primary me-2"
                    @click.prevent="handleImport"
                    v-if="(!client.properties_count && !client.condos_count)"
                >
                  <i class="la la-file-import"/> Imóveis
                </button>

                <button
                    class="btn btn-sm btn-success"
                    @click.prevent="openImportPerson"
                >
                  <i class="la la-file-import"/> Pessoas
                </button>
              </div>

            </div>

            <div v-if="client.import.length" class="mt-4">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Registros</th>
                  <th class="text-center">Avisos</th>
                  <th class="text-center">Erros</th>
                  <th class="text-center">Situação</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in client.import" :key="i.id">
                  <td class="text-center">{{ new Date(i.created_at).toLocaleDateString('pt-BR')}}</td>
                  <td class="text-center">{{ i.type === 'person' ? 'Pessoas' : 'Imóveis' }}</td>
                  <td class="text-center">{{ i.total_properties }}</td>
                  <td class="text-center">{{ i.total_warnings }}</td>
                  <td class="text-center">{{ i.total_errors }}</td>
                  <td class="text-center">
                    <span class="badge bg-success" v-if="i.status === 'finished'">Finalizada</span>
                    <span class="badge bg-warning" v-else>Em Andamento</span>
                  </td>
                  <td>
                    <div class="d-flex align-items-center justify-content-end">
                      <div class="btn-group">
                        <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                                data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                          Ações
                        </button>
                        <ul class="dropdown-menu">
                          <li v-if="i.total_errors"><a class="dropdown-item" href="#" @click.prevent="showErrors(i.id)">Visualizar erros</a></li>
                          <li v-if="i.total_warnings"><a class="dropdown-item" href="#" @click.prevent="showWarnings(i.id)">Visualizar Avisos</a></li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="text-center py-5" v-else>
              <div class="alert alert-warning">Nenhuma importação realizada</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card bg-white">
          <div class="card-body p-4">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title text-primary">Portais integrados</h6>
            </div>

            <div class="table-responsive mt-3" v-if="client.portals.length">
              <table class="table table-striped">
                <tbody>
                <tr v-for="portal in client.portals">
                  <td>{{ portal.portal.name }}</td>
                  <td class="text-end">
                    <div class="btn-group">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                              data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                        Ações
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" @click.prevent="getLastXml(portal.id)">Último Xml gerado</a></li>
                        <li class="dropdown-divider"></li>
                        <li><a class="dropdown-item" href="#" @click.prevent="processExport(portal.portal.id)">Processar exportação</a></li>
<!--                        <li class="dropdown-divider"></li>-->
<!--                        <li><a class="dropdown-item" href="#" @click.prevent="processExport(portal.portal.id)">Gerar XML Vazio</a></li>-->
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div v-else class="text-center py-5">

              <div class="alert alert-warning">Nenhum portal configurado até o momento</div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <modal id="logs-modal" :custom-classes="['modal-dialog-centered', 'modal-lg', 'modal-dialog-scrollable']" :title="modalTitle">
      <template #body>
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="text-center">Referência</th>
            <th>Motivo</th>
            <th class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in errors" :key="item.id">
            <td class="text-center">{{ item.reference }}</td>
            <td>{{ item.error }}</td>
            <td class="text-center">
              <div class="d-flex align-items-center justify-content-end">
                <a class="btn btn-sm btn-primary" target="_blank" :href="`http://vintage.sub100.com.br/imoveis/${item.reference}`"><i class="la la-eye" /></a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </template>

      <template #footer>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" ref="closeModal">Cancelar</button>
      </template>
    </modal>

    <modal
        id="import-person-modal"
        :custom-classes="['modal-dialog-centered', 'modal-xl', 'modal-dialog-scrollable']"
        title="Importar Pessoas"
    >
      <template #body>
        <div class="row g-2">
          <div class="col-6">
            <label class="form-label fw-bold">Arquivo (*.xls, *.xlsx)</label>
            <input
                type="file"
                class="form-control form-control-sm"
                accept=".xls,.xlsx"
                @change="importXls"
                ref="personFile"
            />
          </div>

          <div class="col-4">
            <label class="form-label fw-bold">Tipo de importação</label>
            <select class="form-select form-select-sm" v-model="type" @change="setRequiredFields">
              <option value="">Selecione...</option>
              <option value="users">Usuários</option>
              <option value="realtors">Angariadores/Proprietários</option>
            </select>
          </div>
        </div>

        <hr class="my-4"/>

        <div class="row g-2" v-if="!imported && headers.length">
          <div class="col-6 h-100">
            <span class="title text-primary mb-2 fw-bold">Cadastro de pessoas</span>
            <div class="list-group mt-2">
              <div
                  @dragover="allowDrop"
                  @drop="setField($event, column)"
                  class="list-group-item d-flex align-items-center justify-content-between"
                  v-for="column in columns"
                  :key="`column-${column.column}`"
                  v-if="column.visible"
              >
                <div>
                  <span :class="{'fw-bold': column.required}">{{ column.label }}{{column.required ? '*' : ''}}</span>
                  <span class="ms-2 badge bg-success" v-if="column.value" @dblclick="column.value = null">
                    {{ column.value }}
                    <span @click="column.value = null" class="fw-bold cursor-pointer ms-2 border-start border-white ps-2">X</span>
                  </span>
                </div>

                <div
                    v-if="column.help"
                    class="text-warning cursor-pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    data-bs-html="true"
                    :title="column.help"
                    style="font-size: 1.15rem"
                >
                  <i class="la la-info-circle"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 position-relative h-100 ps-4">
            <div class="position-fixed" style="max-width: 500px; max-height: 55%; overflow-x: auto">
              <span class="title text-primary fw-bold">Colunas encontradas</span>

              <div class="d-flex align-items-center flex-wrap mt-2">
                <span
                    draggable="true"
                    @dragstart="setDataTransfer($event, header)"
                    class="badge bg-primary me-1 mb-2"
                    style="cursor: move"
                    v-for="header in availableHeaders"
                    :key="`header-${header}`"
                >
                  {{ header }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <button type="button" class="btn btn-primary" @click.prevent="handleImportPerson">Importar</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" ref="closeModal">Cancelar</button>
      </template>
    </modal>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import Loader from "@/components/shared/loader.vue";
import {mapActions, mapState} from "vuex";
import axios from "axios";
import Modal from "@/components/shared/Modal.vue";
import {Modal as bsModal, Tooltip} from "bootstrap";
import {read, utils} from 'xlsx';

export default {
  name: 'client-details',
  components: {Modal, Loader, PageHeader},
  props: {},
  data() {
    return {
      modalTitle: 'Erros da importação',
      loading: false,
      errors: [],
      headers: [],
      personCollection: [],
      imported: false,
      type: null,
      statusPayload: {
        status: null,
        reason: null
      },
      resetPayload: {
        client: null,
        validation: null
      },
      columns: [
        {
          label: 'Tipo de pessoa',
          column: 'type',
          value: null,
          required: true,
          help: 'Valores Aceitos: <br/> <strong>"F":</strong> Pessoa física <br/> <strong>"J":</strong> Pessoa jurídica',
          visible: true
        },
        {label: 'Nome', column: 'name', value: null, required: true, visible: true},
        {
          label: 'Imóveis angariados',
          column: 'fundraising',
          value: null,
          required: false,
          help: 'Referências dos imóveis separadas por vírgula: Exemplo APTO-0001,APTO-0002',
          visible: false
        },
        {
          label: 'Proprietário',
          column: 'owner',
          value: null,
          required: false,
          help: 'Referências dos imóveis separadas por vírgula: Exemplo APTO-0001,APTO-0002',
          visible: false
        },
        {label: 'Documento (Cpf/Cnpj)', column: 'document', value: null, required: true, visible: true},
        {label: 'E-mail', column: 'contact.email', value: null, required: false, visible: true},
        {label: 'Whatsapp', column: 'contact.whatsapp', value: null, required: false, visible: true},
        {label: 'Telefone', column: 'contact.phone', value: null, required: false, visible: true},
        {label: 'Cep', column: 'zip_code', value: null, required: false, visible: true},
        {
          label: 'UF',
          column: 'uf',
          value: null,
          required: false,
          help: 'Sigla do estado com 2 caracteres: <strong>Exemplo: PR</strong>',
          visible: true
        },
        {label: 'Cidade', column: 'city', value: null, required: false, visible: true},
        {label: 'Endereço', column: 'address', value: null, required: false, visible: true},
        {label: 'Número', column: 'number', value: null, required: false, visible: true},
        {label: 'Complemento', column: 'complement', value: null, required: false, visible: true},
        {label: 'Unidade/sala', column: 'unity', value: null, required: false, visible: true},
        {label: 'Bloco', column: 'block', value: null, required: false, visible: true},
        {label: 'Bairro', column: 'neighborhood', value: null, required: false, visible: true},
        {label: 'RG', column: 'secondary_document', value: null, required: false, visible: true},
        {label: 'Orgão Emissor', column: 'secondary_document_issuer', value: null, required: false, visible: true},
        {label: 'Data Expedição', column: 'secondary_document_date', value: null, required: false, visible: true},
        {label: 'Sexo', column: 'gender', value: null, required: false, visible: true, help: 'Valores Aceitos: <br/> <strong>"F":</strong> Feminino <br/> <strong>"M":</strong> Masculino',},
        {label: 'Data Nascimento', column: 'birth_date', value: null, required: false, visible: true, help: 'Formato: dd/mm/YYYY'},
        {label: 'Nacionalidade', column: 'nationality', value: null, required: false, visible: true},
        {label: 'Profissão', column: 'occupation', value: null, required: false, visible: true},
        {label: 'Estado Civil', column: 'marital_state', value: null, required: false, visible: true, help: 'Valortes aceitos: <br/>Solteiro <br/>Casado <br/>  Separado <br/>  Divorciado <br/> Viúvo'},
      ],
    }
  },
  async mounted() {
    this.loading = true;
    await this.getOneById(this.$route.params.id).finally(() => {
      this.loading = false;
    })

    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  methods: {
    ...mapActions('Clients', ['getOneById']),
    getLastXml(id) {
      window.open(`${process.env.VUE_APP_API_URL}/xml/${id}`, '_blank')
    },
    openWhatsapp() {
      const whatsapp =  `55${this.client.whatsapp.replace(/\D/g, "")}`;
      window.open(`https://wa.me/${whatsapp}`);
    },
    async processExport(portal) {
      await this.$swal.fire({
        text: 'Confirma o reprocessamento do xml?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.post('/admin/company/process-export', {
            company_id: this.client.id,
            portal_id: portal
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    setRequiredFields() {
      const owner = this.columns.find(column => column.column === 'owner');
      const fundraising = this.columns.find(column => column.column === 'fundraising');
      const email = this.columns.find(column => column.column === 'contact.email');

      if(this.type !== 'realtors') {
        this.$set(this.columns[this.columns.indexOf(owner)], 'value', null);
        this.$set(this.columns[this.columns.indexOf(fundraising)], 'value', null);
      }

      this.$set(this.columns[this.columns.indexOf(email)], 'required', this.type === 'users');
      this.$set(this.columns[this.columns.indexOf(owner)], 'required', this.type === 'realtors');
      this.$set(this.columns[this.columns.indexOf(fundraising)], 'required', this.type === 'realtors');

      this.$set(this.columns[this.columns.indexOf(owner)], 'visible', this.type === 'realtors');
      this.$set(this.columns[this.columns.indexOf(fundraising)], 'visible', this.type === 'realtors');
    },
    async importXls(evt) {

      this.loading = true;
      this.columns.forEach(column => {
        column.value = null
      });
      this.personCollection = [];
      this.imported = false;

      try {
        if (!evt.target.files) return;

        const file = evt.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          const bytes = new Uint8Array(e.target.result);
          const workBook = read(bytes);
          const workSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[workSheetName];

          const data = utils.sheet_to_json(workSheet, {defval:""});
          this.headers = Object.keys(data[0]);
          this.personCollection = data;
        };

        await reader.readAsArrayBuffer(file);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    setDataTransfer(evt, header) {
      evt.dataTransfer.setData('header', header);
    },
    allowDrop(evt) {
      evt.preventDefault();
    },
    setField(evt, column) {
      const header = evt.dataTransfer.getData('header');
      if(!column.value) {
        column.value = header;
      }
    },
    async handleImportPerson() {

      if (this.columns.filter(column => column.required && !column.value).length || !this.type) {
        this.loading = false;
        return this.$swal.fire({
          text: 'Preencha todos os campos obrigatórios',
          icon: 'warning'
        });
      }

      await this.$swal.fire({
        text: 'Confirma a importação de pessoas?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;

          try {
            const columns = this.columns.filter(column => column.value);

            let data = [];
            this.personCollection.forEach(person => {
              const personObject = {};
              columns.forEach(column => {
                personObject[column.column] = person[column.value] ?? null;
              });
              data.push(personObject);
            })

            await axios.post('/admin/company/process-import-person', {
              company_id: this.client.id,
              type: this.type,
              data: data
            }).then(response => {
              this.$swal.fire({
                text: 'A importação foi colocada em fila de processamento. Acompanhe o status abaixo.',
                icon: 'success'
              });
              const modal = bsModal.getOrCreateInstance(document.getElementById('import-person-modal'));
              modal.hide();

            }).finally(() => {
              this.getOneById(this.$route.params.id);
              this.loading = false;
            })

            this.imported = true;
          } catch (e) {
            console.log(e);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async toggleStatus() {
        this.loading = true;
        await axios.post('/admin/company/toggle-status', {
          company_id: this.client.id,
          status: this.statusPayload.status,
          reason: this.statusPayload.reason
        }).then(() => {
          const modal = bsModal.getOrCreateInstance(document.getElementById('status-modal'));
          modal.hide();
        }).finally(() => {
          this.getOneById(this.$route.params.id)
          this.loading = false;
        })
    },
    async resetCompany() {
      this.loading = true;
      await axios.post('/admin/company/process-reset', {
        client: this.resetPayload.client,
        validation: this.resetPayload.validation
      }).then(() => {
        const modal = bsModal.getOrCreateInstance(document.getElementById('reset-modal'));
        modal.hide();
      }).finally(() => {
        this.getOneById(this.$route.params.id)
        this.loading = false;
      })
    },
    async handleImport() {
      await this.$swal.fire({
        title: 'Atenção!',
        text: 'Ao importar os dados quaisquer registros de Imóveis e Condomínios serão apagados e reprocessados. Deseja prosseguir?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.post('/admin/company/process-import', {
            company_id: this.client.id,
          }).then(response => {
            this.$swal.fire({
              text: 'A importação foi colocada em fila de processamento. Acompanhe o status abaixo.',
              icon: 'success'
            });
          }).finally(() => {
            this.getOneById(this.$route.params.id);
            this.loading = false;
          })
        }
      });
    },
    async showErrors(id) {
      await axios.get(`/admin/company/${this.client.id}/imports/logs/${id}?errors=true`).then(response => {
        this.errors = response.data;
        this.modalTitle = 'Erros de importação'
        const modal = bsModal.getOrCreateInstance(document.getElementById('logs-modal'));
        modal.show();
      });
    },
    async showWarnings(id) {
      await axios.get(`/admin/company/${this.client.id}/imports/logs/${id}?warnings=true`).then(response => {
        this.errors = response.data;
        this.modalTitle = 'Avisos de importação'
        const modal = bsModal.getOrCreateInstance(document.getElementById('logs-modal'));
        modal.show();
      });
    },
    login() {
      window.open(`${process.env.VUE_APP_URL}/auth/login?impersonate=${this.client.token}`, '_blank')
    },
    openImportPerson() {
      const modal = bsModal.getOrCreateInstance(document.getElementById('import-person-modal'));
      this.imported = false;
      this.personCollection = [];
      this.headers = [];
      this.$refs.personFile.value = null
      this.columns.forEach(column => column.value = null);
      modal.show();
    },
    openStatusModal() {
      this.statusPayload.status = this.availableStatus[0]?.id;
      this.statusPayload.reason = null;

      const modal = bsModal.getOrCreateInstance(document.getElementById('status-modal'));
      modal.show();
    },
    openResetModal() {

      this.resetPayload.client = this.client.external_id;
      this.resetPayload.validation = null;

      const modal = bsModal.getOrCreateInstance(document.getElementById('reset-modal'));
      modal.show();
    }
  },
  computed: {
    ...mapState('Clients', ['client']),
    availableHeaders() {
      if(!this.headers.length) return [];

      const selectedHeaders = this.columns.filter(column => column.value).map(column => column.value);
      return this.headers.filter(header => !selectedHeaders.includes(header));
    },
    availableStatus() {
      if(!this.client.status) {
        return [];
      }

      const status = [
        {id: 1, label: 'Ativo', status: [2, 3, 4, 5]},
        {id: 3, label: 'Bloqueado', status: [1]},
        {id: 5, label: 'Trial expirado', status: [4]},
        {id: 2, label: 'Inativo', status: [1, 3]},
      ]

      return status.filter(item => item.status.includes(this.client.status));
    }
  }
}
</script>
<style lang="scss" scoped>
.section-title {
  display: block;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 0.1rem 0.1rem;
  color: #666;
}
.address {
  font-size: .85rem;
  display: block;
}
</style>
